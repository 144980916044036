export const en = {
    message: {
        accessMeeting: `Acess Meeting`,
        alreadyReceivedPIN: `I already received a pin by email`,
        cantActivateMeeting: `Cant activate this meeting!`,
        checkEmail: `Check inserted email`,
        chiefComplaint: `Chief complaint`,
        confirmMeeting: `Confirm meeting`,
        confirmingMeeting: `Confirming meeting...`,
        connect: `Connect`,
        connecting: `Connecting...`,
        describeChiefComplaint: `Describe the chief complaint`,
        emailExample: `myemail@email.com`,
        emailSentWithAccessDetails: `An email has been sent to you and the user with access details.`,
        goBack: `Go back`,
        imYourSpecialist: `Hi, i'm your specialist!`,
        insertMeetingDateAndTime: `Insert the meeting date and time`,
        insertPINReceivedEmail: `Insert here your meeting pin received by email.`,
        insertRegisteredEmail: `Insert your registered email`,
        letsSolveCaseTogether: `Let’s solve you clinical case together!`,
        meetingConfirmed: `Meeting confirmed`,
        meetingPIN: `Meeting PIN`,
        meetingUnavailable: `Meeting unavailable`,
        meetingUnavailableDetails: `The meeting you are trying to access is <strong>unavailable</strong> at this time!<br><br>Check the confirmation email and try again at the scheduled time.<br><br>Thanks.`,
        registrationSubtitle: `Talk with your favorite veterinarian!`,
        registrationTitle: `Video consultation`,
        requestConsultation: `Request Consultation`,
        scheduleMeeting: `Schedule meeting`,
        thankYou: `Thank you!`,
        vetSpecialists: `Vet Specialists`,
        veterinaryCenter: `Veterinary Center`,
        veterinarySurgeon: `Veterinary Surgeon`,
        waitAMomentPlease: `Wait a moment please...`,
        weWillConfirmAsSoonAsPossibleAndSendEmail: `We will confirm it as soon as possible and send you an email with instructions.`,
        yourEmail: `Your email`,
        yourRequestHasBeenSent: `Your request has been sent to our specialist.`,

        backend: `Backend`,
        createUpdateYourUsers: `Create or update your users!`,
        newUser: `New user`,
        image: `Image`,
        specialistName: `Specialist Name`,
        email: `Email`,
        operations: `Operations`,
        createNewSpecialist: `Create new specialist`,
        description: `Description`,
        backendPassword: `Backend password`,
        confirmation: `Confirmation`,
        save: `Save`,
        cancel: `Cancel`,

        edit: `Edit`,
        delete: `Delete`,

        confirmUserRemoval: 'Do you want to remove this user?',
        userRemoved: `User removed!`,
        errorRemovingUser: `Error while removing user!`,
        newUserAdded: `New user added!`,
        errorCreatingNewUser: `Error creating new user!`,
        errorPasswordsDontMatch: `Passwords don't match!` ,

        userDataSaved: `User data saved!`,
        errorChangingUserData: `Error changing user data!`,
        errorUploadingImage: `Error uploading image!`,

    }
}