import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Home from '../views/Home.vue'
import Meeting from "@/views/Meeting.vue";
import RequestSent from "@/views/RequestSent.vue";
import ConfirmMeeting from "@/views/ConfirmMeeting.vue";
import JitsiIframe from "@/components/JitsiIframe.vue";
import Backend from "@/views/Backend.vue";
import Login from "@/views/Login.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/meeting/:pin?',
        name: 'Meeting',
        component: Meeting
    },
    {
        path: '/requestsent/:payment_hash',
        name: 'Request Sent',
        component: RequestSent
    }, {
        path: '/confirmmeeting/:hash',
        name: 'Meeting Confirmation',
        component: ConfirmMeeting
    }, {
        path: '/backend',
        name: 'Backend',
        meta: {requiresAuth: true},
        component: Backend,
    }, {
        path: '/login',
        name: 'Login',
        component: Login
    },
    /*{
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/!* webpackChunkName: "about" *!/ '../views/About.vue')
    }*/
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

console.log(process.env.BASE_URL);

router.beforeEach((to, from) => {
    // instead of having to check every route record with
    // to.matched.some(record => record.meta.requiresAuth)
    if (to.meta.requiresAuth && !localStorage.getItem('token')) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        return {
            path: '/login',
            // save the location we were at to come back later
            query: {redirect: to.fullPath},
        }
    }
})

export default router
