

import {defineComponent} from 'vue';
import flatpickr from 'flatpickr'

export default defineComponent({
  name: "ConfirmMeeting",
  data() {
    return {
      pin: '',
      email: '',
      datetime: '',
      isEmailFilled: false,
      isConfirming: true,
      isConfirmed: false,
    }
  },
  methods: {
    reset() {
      this.isEmailFilled = false
      this.isConfirming = true
    },
    fetchEmail(){
      const meetingConfirmationHash = this.$route.params['hash']

      this.$store.dispatch('checkMeeting',meetingConfirmationHash)
          .then((meetingInfo: any) => {
            this.email = meetingInfo.requester_user_email
          })
          .catch(() => {
            console.error('error_fetch_meeting_data')
          })
    },
    confirmEmail() {
      if (this.email.length >= 6) {
        this.isEmailFilled = true
        this.confirmMeeting()
      }
    },
    confirmMeeting() {
      // get meeting activation hash from url
      const meetingConfirmationHash = this.$route.params['hash']
      let date = new Date()

      // request API meeting confirmation
      this.$store.dispatch('confirmMeeting', {
        hash: meetingConfirmationHash,
        email: this.email,
        schedule: this.datetime,
        timezone_diff: date.getTimezoneOffset()*60
      }).then((data: any) => {
        this.pin = data['meeting_pin']
        this.isConfirming = false
        this.isConfirmed = true
      }).catch(() => {
        this.isConfirming = false
        this.isConfirmed = false;
      })
    }
  },
  mounted() {
    this.fetchEmail()
    // flatpickr(`#schedule`, {
    //   enableTime: true,
    //   time_24hr: true,
    //   dateFormat: "Y-m-d H:i",
    //   minDate: 'today'
    // })
  },
  computed: {
    meetingURL(): string {
      return `/meeting/${this.pin}`
    }
  }
});
