
import {defineComponent} from 'vue';
import {mapActions, mapGetters} from "vuex";
import router from "@/router";
import {APIURL} from "@/store/config";

export default defineComponent({
  name: 'Home',
  data() {
    return {
      isCreatingMeeting: false,
      name: '',
      petName: '',
      email: '',
      description: '',
      requested_user: 0,
      paymentHash: '',
    }
  },
  computed: {
    ...mapGetters(['getUsers']),
    requestedUserData(): any {
      if (this.requested_user > 0) {
        return this.getUsers.filter((user: any) => {
          return user.user_id === this.requested_user
        })
      } else {
        return null
      }
    },
    specialistProfileImageURL(): string {
      return `${APIURL}users/image/${this.requested_user}`
    }
  },
  methods: {
    ...mapActions(['fetchUsers']),
    requestMeeting() {
      this.isCreatingMeeting = true;
      this.$store.dispatch('requestMeeting', {
        name: this.name,
        email: this.email,
        description: this.description,
        pet_name: this.petName,
        requested_user: this.requested_user,
      }).then((res: any) => {
        this.paymentHash = res.meeting_hash

        this.isCreatingMeeting = false;

        router.push(`/requestsent/confirm`)

        // payment processing disabled
        /*
        * setTimeout(() => {
        *   this.submitPayment();
        * }, 0)
         */
      })
    },
    submitPayment() {
      const form = document.getElementById("paymentForm")
      if (form)
        (form as any).submit()
    }
  },
  mounted() {
    this.fetchUsers()
  }
});
